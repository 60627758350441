module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Warelma site","short_name":"Warelma","start_url":"/","background_color":"#FFFFFF","theme_color":"#DA251C","display":"minimal-ui","icon":"src/images/favicon-32x32.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"74d752bc6ccdbe33b7e9c0cdbd911813"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"warelma","accessToken":"MC5ZRGdMOUJFQUFDTUFQWEl5.77-977-977-977-977-9EGvvv70v77-9cu-_ve-_vQNBCSoefe-_ve-_vTLvv71977-9Lyjvv70j77-9Ze-_vQ","schemas":{"homepage":{"Main":{"title":{"type":"StructuredText","config":{"single":"heading1","label":"Titel"}},"subtitle_1":{"type":"StructuredText","config":{"single":"heading2","label":"Subtitel 1"}},"subtitle_2":{"type":"StructuredText","config":{"single":"heading2","label":"Subtitel 2"}},"quote":{"type":"StructuredText","config":{"single":"paragraph","allowTargetBlank":true,"label":"Citaat"}},"over_ons":{"type":"StructuredText","config":{"multi":"paragraph, preformatted, heading3, strong, em, hyperlink, image","allowTargetBlank":true,"label":"Over ons"}},"text_service_1":{"type":"StructuredText","config":{"multi":"paragraph, heading3, strong, em, hyperlink, image","allowTargetBlank":true,"label":"tekst zandstralen"}},"text_service_2":{"type":"StructuredText","config":{"multi":"paragraph, heading2, strong, em, hyperlink, image","allowTargetBlank":true,"label":"tekst luchtgommen"}}}},"referentie":{"Main":{"title":{"type":"StructuredText","config":{"single":"heading2","label":"Titel"}},"reference_text":{"type":"StructuredText","config":{"multi":"paragraph, strong, em, hyperlink, image","allowTargetBlank":true,"label":"Tekst"}},"images":{"type":"Group","config":{"fields":{"image":{"type":"Image","config":{"constraint":{"width":800,"height":1050},"thumbnails":[],"label":"image"}}},"label":"Afbeeldingen"}}}}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
